<template>
  <section class="user-profile">
    <br>
    <md-card class="md-layout-item md-size-50 md-small-size-100">
      <md-card-header>
        <div class="md-title">
          <md-icon>verified_user</md-icon> User Profile
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label :for="$id('given-name')">Display Name</label>
              <md-input name="first-name" :id="$id('given-name')" autocomplete="given-name"
                v-model="user.displayName" :disabled="updating"/>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label :for="$id('given-email')">Email</label>
              <md-input name="emailUser" :id="$id('given-email')" autocomplete="given-email"
                v-model="user.email" :disabled="updating"/>
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-card-actions>
        <md-button class="md-raised md-primary"
          :disabled="updating" @click="updateEmail">Update</md-button>
      </md-card-actions>
    </md-card>
    <br>
    <br>
    <md-card class="md-layout-item md-size-50 md-small-size-100" style="padding-bottom:2rem;">
      <md-card-header>
        <div class="md-title">
          <md-icon>dangerous</md-icon>Privacy / Danger Zone</div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-gutter">
          <!-- <div class="md-layout-item md-small-size-100">
            <md-field>
              <label for="new-pass">Remaining Downloads</label>
              <md-input name="quota" disabled id="quota" type="text"
                :value="getRemainingQuota || 'N/A'"/>
            </md-field>
          </div> -->
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label :for="$id('new-pass')">Set New Password</label>
              <md-input name="new-pass" :id="$id('new-pass')" type="password"
                v-model="user.newPassword" :disabled="updating"/>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field>
              <label :for="$id('new-pass-confirm')">Confirm New Password</label>
              <md-input name="confirm-pass" :id="$id('new-pass-confirm')" type="password"
                v-model="user.confirmNewPassword" :disabled="updating"/>
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-card-actions class="md-alignment-center">
        <md-button class="md-raised md-primary md-alignment-center"
                   :disabled="updating" @click="updatePassword">
          Change
        </md-button>
      </md-card-actions>
      <br/>
      <div class="md-layout md-gutter" v-if="deleting">
        <div class="md-layout-item md-small-size-100">
          <p class="text-danger">
              Retype your email in order to confirm the termination of your account on Fac3d.xyz
            </p>
          <md-field>
            <label :for="$id('del-email')">
              Email
            </label>
            <md-input :id="$id('del-email')" name="confirmName" v-model="confirmDisplayName">
            </md-input>
          </md-field>
        </div>
        <md-button class="md-raised md-accent"
          :disabled="confirmDisplayName.length === 0 || confirmDisplayName !== user.email"
          @click="deleteUser">
          Confirm
        </md-button>
      </div>
      <md-button :class="deleting ? 'md-raised md-primary' : 'md-raised md-accent'"
        @click="deleting = !deleting"
        v-text="deleting ? 'Cancel' : 'Delete Account'">
      </md-button>
    </md-card>
    <br/>
    <md-card class="md-layout-item md-size-50 md-small-size-100" style="padding-bottom:2rem;">
      <md-card-content>
        <AboutComponent/>
      </md-card-content>
    </md-card>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import firebase from 'firebase';
import AboutComponent from '../components/AboutComponent.vue';

export default {
  name: 'UserProfile',
  components: {
    AboutComponent,
  },
  computed: {
    ...mapGetters(['getUser', 'getRemainingQuota']),
  },
  data() {
    return {
      updating: false,
      deleting: false,
      user: {
        displayName: '',
        email: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      confirmDisplayName: '',
    };
  },
  methods: {
    updateUser() {
      const user = firebase.auth().currentUser;
      this.updating = true;
      user.updateProfile({
        displayName: this.user.displayName,
        // photoURL: profilePhoto
      })
        .then(() => {
          this.$store.dispatch('setAlert', {
            show: true,
            title: 'Profile updated successfully!',
            content: '',
          });
          this.updating = false;
        })
        .catch(() => {
          this.updating = false;
        });
    },
    updateEmail() {
      const user = firebase.auth().currentUser;
      this.updating = true;
      user.updateEmail(this.user.email).then(() => {
        // Update successful.
        this.updating = false;
        this.updateUser();
      }).catch(() => {
        // An error happened.
        this.updating = false;
      });
    },
    updatePassword() {
      if (this.user.newPassword !== this.user.confirmNewPassword) {
        this.user.newPassword = '';
        this.user.confirmNewPassword = '';
        this.$store.dispatch('setAlert', {
          show: true,
          title: 'Please re-type your new password and confirm it correctly :/',
          content: '',
        });
      } else {
        const user = firebase.auth().currentUser;
        this.updating = true;
        user.updatePassword(this.user.newPassword).then(() => {
          // Update successful.
          this.updating = false;
        }).catch(() => {
          // An error happened.
          this.updating = true;
        });
      }
    },
    async deleteUser() {
      const user = firebase.auth().currentUser;
      user.delete()
        .then(async () => {
          await this.$store.dispatch('userLogout');
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
  },
  mounted() {
    this.user.email = this.getUser.email;
    this.user.displayName = this.getUser.displayName;
  },
};
</script>
<style lang="scss" scoped>
.user-profile {
  padding: 0 2rem;
  margin-bottom: 32px;
  .md-title {
    color: #888;
  }
  .md-card.md-theme-default {
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(80px);
    filter: drop-shadow(6px 4px 4px rgba(57, 40, 166, 0.3));
    * {
      color: #000001 !important;
      -webkit-text-fill-color: #333;
    }
  }
}
.md-gutter {
  padding: 0 2rem;
}
</style>
